// extracted by mini-css-extract-plugin
export var banner = "platform-module--banner--c8SFa";
export var paddingTop100 = "platform-module--paddingTop100--ngQpA";
export var TextCenterBoldWhite = "platform-module--TextCenterBoldWhite--opHcA";
export var fontWhite = "platform-module--fontWhite--SXLwB";
export var center = "platform-module--center--PLazw";
export var card_left_bg = "platform-module--card_left_bg--s171P";
export var bussinesscard = "platform-module--bussinesscard--Wfg7V";
export var TextCenterBoldBlackSmall = "platform-module--TextCenterBoldBlackSmall--kvM4G";
export var TextCenterBlackSmall = "platform-module--TextCenterBlackSmall--v6QGN";
export var card_right_bg = "platform-module--card_right_bg--jTfxq";
export var heightFull = "platform-module--heightFull--YZCtx";
export var TextLeftBold = "platform-module--TextLeftBold--peNZZ";
export var TextLeftBolds = "platform-module--TextLeftBolds--Fov+i";
export var TextJustify = "platform-module--TextJustify--aZRVc";
export var bussinessLaboratory = "platform-module--bussinessLaboratory--hxVzC";
export var bussinessLaboratoryModules = "platform-module--bussinessLaboratoryModules--TT26b";
export var angle2 = "platform-module--angle2--YVoEo";
export var blModuleFlow = "platform-module--blModuleFlow--Jn-7v";
export var whyCards = "platform-module--whyCards--eIF-4";
export var challengeDetails = "platform-module--challengeDetails--N-VoY";
export var PlatformActionButtons = "platform-module--PlatformActionButtons--Q+2BU";
export var blJobSimulator = "platform-module--blJobSimulator--Gc7fc";
export var bussinessLaboratoryJobRoles = "platform-module--bussinessLaboratoryJobRoles--LAfvI";
export var blJobFlow = "platform-module--blJobFlow--Ni-Y9";
export var aboutTitle = "platform-module--aboutTitle--wm3Zv";
export var Contactuspage = "platform-module--Contactuspage--AzYJj";
export var become_btn = "platform-module--become_btn--4KnJF";
export var modulesinbusiness = "platform-module--modulesinbusiness--b5d5a";
export var profinance = "platform-module--profinance--KQZfS";
export var contactus = "platform-module--contactus--peuC3";