import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Icon } from 'react-icons-kit'
import Header from "../components/header_v1"
import { facebookOfficial } from 'react-icons-kit/fa/facebookOfficial'
import { linkedinSquare } from 'react-icons-kit/fa/linkedinSquare'
import { youtubePlay } from 'react-icons-kit/fa/youtubePlay'
import { twitter } from 'react-icons-kit/fa/twitter'
import { Row, Container, Col, Button } from "react-bootstrap";
// import * as Styles from "../style/home.module.scss";
import * as Styles from "../style/platform.module.scss";
import CallToAction from "../components/button/index";

//Images and Svg Import
import logoSVG from "../assets/images/Logo.png";

import angle2 from "../assets/images/angle2.svg";

import simulating_work from "../assets/images/BLModules/simulating_work.png";
import business_setup from "../assets/images/BLModules/business_setup.png";
import salary_payroll from "../assets/images/BLModules/salary_payroll.png";
import purchase_to_process from "../assets/images/BLModules/purchase_to_process.png";
import sales_cash_process from "../assets/images/BLModules/sales_cash_process.png";
import inventory_management from "../assets/images/BLModules/inventory_management.png";
import fixed_assets from "../assets/images/BLModules/fixed_assets.png";
import banking_insurance from "../assets/images/BLModules/banking_insurance.png";
import income_tax from "../assets/images/BLModules/income_tax.png";
import gst_return_filling from "../assets/images/BLModules/gst_return_filling.png";
import statutory_work_filling from "../assets/images/BLModules/statutory_work_filling.png";
import product_costing from "../assets/images/BLModules/product_costing.png";
import budget_preparation from "../assets/images/BLModules/budget_preparation.png";
import capital_budgeting from "../assets/images/BLModules/capital_budgeting.png";
import cash_flow from "../assets/images/BLModules/cash_flow.png";
import bussiness_valuation from "../assets/images/BLModules/bussiness_valuation.png";
import mis_preparations from "../assets/images/BLModules/mis_preparations.png";
import account_review from "../assets/images/BLModules/account_review.png";
import financial_preparing from "../assets/images/BLModules/financial_preparing.png";
import financial_statement from "../assets/images/BLModules/financial_statement.png";

import Role1 from "../assets/images/BLModules/role_1.png";
import Role2 from "../assets/images/BLModules/role_2.png";
import Role3 from "../assets/images/BLModules/role_3.png";
import Role4 from "../assets/images/BLModules/role_4.png";
import Role5 from "../assets/images/BLModules/role_5.png";
import role_start from "../assets/images/BLModules/role_start.png";
import role_end from "../assets/images/BLModules/role_end.png";
import proAccountant from "../assets/images/BLModules/proAccountant.png";
import Contactus from "../components/Contactus"

import { navigate } from 'gatsby';
const PlatformPage = () => {
  const comingsoon = () => {
    navigate("/coming-soon")
  }
  const imagePath = "../assets/images/BLModules/";

  const blJobRoles = [
    {
      imgUrl: Role1,
      role: "Accounts Officer",
    },
    {
      imgUrl: Role2,
      role: "BPO Specialist",
    },
    {
      imgUrl: Role3,
      role: "Tax Accountant",
    },
    {
      imgUrl: Role4,
      role: "Financial Planner and Analyst",
    },
    {
      imgUrl: Role5,
      role: "Stores Officer",
    }
  ]
  const blModules = [
    {
      imgUrl: simulating_work,
      heading: "Simulating work experience in Accounting and Tax fields",
    },
    {
      imgUrl: business_setup,
      heading: "Business Setup & Registration works",
    },
    {
      imgUrl: salary_payroll,
      heading: "Salary and payroll process",
    },
    {
      imgUrl: purchase_to_process,
      heading: "Purchase to pay process",
    },
    {
      imgUrl: sales_cash_process,
      heading: "Sales order to cash process",
    },
    {
      imgUrl: inventory_management,
      heading: "Inventory managemet and valautaion",
    },
    {
      imgUrl: fixed_assets,
      heading: "Fixed Asserts",
      details: "Practical Training Software for Accounts and Tax"
    },
    {
      imgUrl: banking_insurance,
      heading: "Banking & Insurance",
    },
    {
      imgUrl: income_tax,
      heading: "Income Tax works & Returns Filing",
    },
    {
      imgUrl: gst_return_filling,
      heading: "GST works & Returns filing",
    },
    {
      imgUrl: statutory_work_filling,
      heading: "Statutory works & Filings",
    },
    {
      imgUrl: product_costing,
      heading: "Product Costing",
    },
    {
      imgUrl: budget_preparation,
      heading: "Budget Preparation",
    },
    {
      imgUrl: capital_budgeting,
      heading: "Capital Budgeting analysis",
    },
    {
      imgUrl: cash_flow,
      heading: "Cash Flow projections",
    },
    {
      imgUrl: bussiness_valuation,
      heading: "Business Valuation",
    },
    {
      imgUrl: mis_preparations,
      heading: "MIS Preparations",
    },
    {
      imgUrl: account_review,
      heading: "Accounts Reviews & Audit works",
    },
    {
      imgUrl: financial_preparing,
      heading: "Financial (P&L, B/S) Preparing",
    },
    {
      imgUrl: financial_statement,
      heading: "Analysis of Financial statements",
    }
  ]
  return (
    <Layout>
      <Seo title="Platform" />
      <div id="Home" className={Styles.banner}>
        <Header />
        <hr style={{ border: "1px solid #FFFFFF", height: "0.005em", color: "#ffffff", backgroundColor: "#ffffff" }} />
        <Container fluid >
          <Row>
            <Col md={12} >
              <div className={`${Styles.TextCenterBoldWhite} ${Styles.paddingTop100}`}  >
                Skill Elevator Platforms
              </div>

            </Col>
          </Row>
          <Row>
            <Col md={12} >
              <p className={`${Styles.fontWhite} ${Styles.center}`}  >
                <span style={{ color: "#fff", backgroundColor: "rgb(50, 163, 238)", padding: "7px", margin: "0px auto" }}>  The best platform for commerce skilling & job enhancement</span>
              </p>
            </Col>
          </Row>
          <Row style={{ paddingTop: "90px", }} className={Styles.bussinesscard}>
            <Col md={2}></Col>
            <Col md={4}>
              <div className={` ${Styles.card_left_bg}`} style={{ paddingTop: "60px" }}>
                <p className={`${Styles.TextCenterBoldBlackSmall}`}  >Business Laboratory</p>
                <p className={`${Styles.TextCenterBlackSmall}`}  >A digital laboratory to enhance <br /> accounting skills</p>
                <div style={{ width: "150px", alignContent: "center", margin: "0 auto" }} onClick={() => comingsoon()}>
                  <CallToAction title="BIZLAB" size="xl" />
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className={` ${Styles.card_right_bg}`} style={{ paddingTop: "39px" }}>
                <p className={`${Styles.TextCenterBoldBlackSmall}`}  >Job Simulator</p>
                <p className={`${Styles.TextCenterBlackSmall}`}  >An online platform to enhance <br /> accounting employment</p><br />
                <div style={{ width: "150px", alignContent: "center", margin: "0 auto" }} onClick={() => comingsoon()}>
                  <CallToAction title="JOSIM" size="xl" />
                </div>
              </div>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Container>
      </div>


      <div >
        <Container fluid>
          <Row className={Styles.heightFull} style={{ marginTop: "10px" }}>
            <Col md={2}></Col>
            <Col md={4} >
              <div className={`${Styles.TextLeftBold} ${Styles.paddingTop100}`}>
                About Business-Laboratory
              </div>
              <div className={Styles.TextJustify}>
                Business laboratory is a consolidation of 20 modules that represents various transactional work in every company or business. The Objective of this paltform is to give an opportunity to student to undergo needed learnning on realtime works and feel like working in a company. It is Laboratory for Business in a way that enable sudent to experiement themselves with virual transactions in the business. Student need to register each module and complete the module, one after one. After register, each subscriber will be given an opportunity to work with one virtual company. Each module give a feel of real time office/ work place job and subscriber will be given limited level of transactions in the screen. As an example, 'Practical Accounting and Book Keeping' module gives an insight in to the accounting entries using various vouchers and bill. It gives the complete work experience in accounting. Each module will be given some score and only after achiving minium score he/she will be given an option to move forward to next module After each module completion they will be given pratcial training certificate. (Equal to 2 years of experianced person in the Industry)
              </div>
              <div className="row mt-4">
                <Col xs={7} md={6}><CallToAction title="Signup for free" size="xl" /></Col>
                <Col xs={5} md={6}><CallToAction title="Checkdemo" size="xl" type="outlined" vlassName={Styles.btns}/></Col>
              </div>
            </Col>
            <Col md={5} className={Styles.bussinessLaboratory}></Col>
            <Col md={1}></Col>
          </Row>
        </Container>
      </div>

      <div id="Business Laboratory Modules" className={Styles.bussinessLaboratoryModules}>
        <img src={angle2} className={`${Styles.angle2} d-block d-sm-none`} />
        <Container>
          <h2 className={Styles.TextLeftBolds} >20 modules in Business Laboratory</h2>
          <div className={Styles.blModuleFlow}>
            <Row >
              {blModules.map((whyItem) => (
                <Col md={3} className="mb-3">
                  <div className={Styles.whyCards} style={{ textAlign: 'center', minHeight: '135px', maxWidth: '240px' }}>
                    <div>
                      <img src={whyItem.imgUrl} className="mb-3" alt="why"
                        style={{ width: "55px", height: "55px" }} />
                    </div>
                    <div>
                      <p className={Styles.challengeDetails} style={{ marginBottom: 5 }}>{whyItem.heading}</p>
                    </div>
                  </div>
                </Col>
              ))}
              <div className="row mt-12"className={Styles.become_btn} >
                <Col xs={12} md={12}>
                  <Button className={Styles.PlatformActionButtons}>Become Pro Accountant
                  </Button>
                </Col>
              </div>
            </Row>
          </div>
        </Container>
      </div>

      <div>
        <Container fluid>
          <Row className={Styles.heightFull} style={{ marginTop: "-10px" }}>
            <Col md={2}></Col>
            <Col md={4} >
              <div className={`${Styles.TextLeftBold} ${Styles.paddingTop100}`}>
                About Job-Simulator
              </div>
              <div className={Styles.TextJustify} >
                It is a web platform consists of various working modules created for various job roles.
                It has five job roles such as (a) Accounts Officer (b) BPO Specialist © Tax Accountant (d) FP&A (e) Compliance Officer Each role is created based on what exactly one person does any work once he/she get job. It is conolidation of all possible works that one can do in the Industry. Before starting this module, student has to complete the Business Laboratory which gives different set of work experience as a first step. Using all the modules or transactions process, one can easily understand any job role. After completion of each job student will get a certificate like as an example after completing Accounts Officer Training, he/she gets "Practial On-Job Training Completion Certificate for Accounts Officer" After completion of all 5 Job Training he will be awarded a qualification as " ProFinance Manager" (Equal to 3 years of experianced person in the Industry)
              </div>
              <div className="row mt-4">
                <Col xs={7} md={6}><CallToAction title="Signup for free" size="xl" /></Col>
                <Col xs={5} md={6}><CallToAction title="Checkdemo" size="xl" type="outlined" /></Col>
              </div>
            </Col>
            <Col md={5} className={Styles.blJobSimulator}></Col>
            <Col md={1}></Col>
          </Row>
        </Container>
      </div>

      <div id="Working modules" className={Styles.bussinessLaboratoryJobRoles}
       >
        <Container>
          <h2 className={Styles.TextLeftBolds}>Working modules for various job roles</h2>
          <Row className={Styles.blJobFlow}>
            <Col md={4}></Col>
            <Col md={4}>

              {blJobRoles.map((jobRole) => (
                <div className={Styles.whyCards} style={{ marginBottom: '20px' }}>
                  <div style={{ float: 'left' }}>
                    <img src={jobRole.imgUrl} alt="why"
                      style={{ width: "36px", height: "36px", marginTop: '-5px' }} />
                  </div>
                  <div>
                    <p className={Styles.aboutTitle} style={{ marginBottom: 9, marginLeft: '3em' }}>{jobRole.role}</p>
                  </div>
                </div>
              ))}

              <div className="row mt-12" style={{ paddingTop: "20px" }} >
                <Col xs={9} md={9}>
                  <Button className={Styles.PlatformActionButtons}>ProFinance Manager </Button>
                </Col>
                <Col xs={3} md={3} className={Styles.profinance}>
                  <img src={proAccountant} alt="proFinance"
                    style={{ width: "40px", height: "40px", paddingTop: 10, paddingLeft: 5 }} />
                </Col>
              </div>
            </Col>
            <Col md={4}></Col>
          </Row>
        </Container>

      </div>
    
        <div className={Styles.Contactuspage}>
        <Container className={Styles.contactus}>
          <Contactus />
        </Container>
      </div>
  
    </Layout >
  )
}
export default PlatformPage
